import AdvancedSearchClusters from "../graphql/Search/AdvancedSearchClusters.gql";
import moment from "moment";
import { i18n } from "~/plugins/translator";

export const state = () => ({
  activeTab: "PROPERTY",
  searchDebounce: null,
  queryFilters: null,
  resultState: "map", // list, map
  mapZoom: undefined,
  mapCenter: undefined,
  selectedPropertyType: undefined,
  clusters: {
    ALL: [],
    PROPERTY: [],
    COMPANY: [],
    PERSON: [],
  },
  filters: {
    ALL: [],
    PROPERTY: [],
    COMPANY: [],
    PERSON: [],
  },
  offset: {
    ALL: 0,
    PROPERTY: 0,
    COMPANY: 0,
    PERSON: 0,
  },
});

export const mutations = {
  setActiveTab(state, newTab) {
    state.activeTab = newTab;
  },

  setResultState(state, newState) {
    state.resultState = newState;
  },

  searchDebounce(state) {
    clearTimeout(state.searchDebounce);

    state.searchDebounce = setTimeout(() => {
      this.app.store.commit("msga/search");
    }, 400);
  },

  search(state) {
    let filter = getFilters(state);
    if (state.activeTab === "ALL") {
      filter.includedTypes = ["PROPERTY", "COMPANY", "PERSON"];
    }

    this.app.store.commit("msga/setQueryFilters", filter);
    this.app.store.commit("msga/searchClusters");
  },

  searchClusters(state, coords) {
    let filter = getFilters(state, coords);
    if (state.activeTab === "ALL") {
      filter.includedTypes = ["PROPERTY", "COMPANY", "PERSON"];
    }
    this.app.apolloProvider.defaultClient
      .query({
        query: AdvancedSearchClusters,
        variables: { input: filter },
      })
      .then((resp) => {
        this.app.store.commit("msga/setClusters", resp.data.clusters);
      });
  },

  //ADDERS AND REMOVERS
  setClusters({ clusters, activeTab }, result) {
    clusters[activeTab] = result;
  },

  //SETTERS
  setOffset({ offset, activeTab }, newOffset) {
    offset[activeTab] = newOffset;
  },

  removeFilter({ filters, activeTab }, filter) {
    const index = filters[activeTab].findIndex((itm) => itm.id === filter.id);
    if (index < 0) return;
    filters[activeTab].splice(index, 1);

    this.app.store.commit("msga/searchDebounce");
  },

  clearFilters({ filters, activeTab }) {
    filters[activeTab] = [];
    this.app.store.commit("msga/searchDebounce");
  },

  upsertFilter({ filters, activeTab }, filter, omitSearch) {
    const index = filters[activeTab].findIndex((itm) => itm.id === filter.id);
    if (index >= 0) {
      filters[activeTab].splice(index, 1, filter);
    } else {
      filters[activeTab].push(filter);
    }
    if (!omitSearch) {
      this.app.store.commit("msga/searchDebounce");
    }
  },

  setQueryFilters(state, filters) {
    state.queryFilters = filters;
  },

  setMapZoom(state, zoom) {
    state.mapZoom = zoom;
  },

  setMapCenter(state, center) {
    state.mapCenter = center;
  },

  setSelectedPropertyType(state, type) {
    state.selectedPropertyType = type;
  },
};

export const getters = {
  getActiveTab(state) {
    return state.activeTab;
  },

  getOffset({ offset, activeTab }) {
    return offset[activeTab];
  },

  getFilters({ filters, activeTab }) {
    return filters[activeTab];
  },

  getClusters({ clusters, activeTab }) {
    return clusters[activeTab];
  },

  getSearchQuery(state) {
    return state.queryFilters;
  },

  getResultState(state) {
    return state.resultState;
  },

  getMapZoom(state) {
    return state.mapZoom;
  },

  getMapCenter(state) {
    return state.mapCenter;
  },

  getSelectedPropertyType(state) {
    return state.selectedPropertyType;
  },
};

export const actions = {
  setDrawDone({ commit }) {
    this.app.router.push("/explore");

    const coordinates = this.app.store.getters["areas/getDrawnCoordinates"];

    this.app.store.commit(
      "msga/upsertFilter",
      {
        id: "polygon",
        type: "polygon",
        label: i18n.t("filters.polygon"),
        elastic: "centroidGIS",
        enabled: true,
        active: true,
        value: coordinates,
        metadataLoaded: true,
      },
      true
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

const getFilters = function (state, coords) {
  let searchText;

  if (state.activeTab === "PROPERTY") {
    searchText = generateQuery(state.filters[state.activeTab]);
  } else {
    searchText = state.filters[state.activeTab][0]?.value;
  }

  let filter = {
    limit: 10,
    offset: state.offset[state.activeTab],
    text: searchText,
    includedTypes: state.activeTab,
    zoom: 8,
    topLeft: {
      lon: 8.08997684086,
      lat: 57.730016588,
    },
    bottomRight: {
      lon: 12.6900061378,
      lat: 54.8000145534,
    },
  };

  if (coords) {
    filter.zoom = Math.floor(coords.zoom + 0.3 * coords.zoom);
    filter.topLeft = {
      lat: coords.bounds._ne.lat,
      lon: coords.bounds._sw.lng,
    };
    filter.bottomRight = {
      lat: coords.bounds._sw.lat,
      lon: coords.bounds._ne.lng,
    };
  }

  if (filter.zoom >= 20) {
    filter.zoom = 29;
  }

  return filter;
};

const FILTER_TYPE_RANGE = "range";
const FILTER_TYPE_TEXT = "text";
const FILTER_TYPE_SEARCH = "search";
const FILTER_TYPE_DATE = "date";
const FILTER_TYPE_POLYGON = "polygon";

const generateQuery = function (filters) {
  if (filters.length === 0) {
    return JSON.stringify({ match_all: {} });
  }

  let query = {
    bool: {
      must: [],
    },
  };

  filters.forEach((filter) => {
    if (!filter.active) {
      return;
    }

    switch (filter.type) {
      case FILTER_TYPE_DATE:
      case FILTER_TYPE_RANGE:
        addRangeQuery(query, filter);
        break;

      case FILTER_TYPE_TEXT:
        addTextQuery(query, filter);
        break;

      case FILTER_TYPE_SEARCH:
        addSearchQuery(query, filter);
        break;

      case FILTER_TYPE_POLYGON:
        addPolygonQuery(query, filter);
        break;

      default:
        if (Array.isArray(filter.value)) {
          addMatchMultipleQuery(query, filter);
        } else {
          addMatchSingleQuery(query, filter);
        }
    }
  });

  return JSON.stringify(query);
};

const addRangeQuery = function (query, filter) {
  if (filter.value == null || filter.value.length === 0 || (!filter.value[0] && !filter.value[1])) {
    return;
  }

  let obj = {};
  const elasticType = "range";

  obj[elasticType] = {};
  obj[elasticType][filter.elastic] = {};

  if (filter.value[0] != null && filter.value[0] !== "") {
    obj[elasticType][filter.elastic].gte = filter.value[0];
  }

  if (filter.value[1] != null && filter.value[1] !== "") {
    obj[elasticType][filter.elastic].lte = filter.value[1];
  }

  query.bool.must.push(obj);
};

const addTextQuery = function (query, filter) {
  if (!filter.value) {
    return;
  }

  let obj = {};
  const elasticType = "match_phrase_prefix";

  obj[elasticType] = {};
  obj[elasticType][filter.elastic] = filter.value;

  query.bool.must.push(obj);
};

const addSearchQuery = function (query, filter) {
  if (!filter.value) {
    return;
  }

  let obj = {};
  const elasticType = "multi_match";

  obj[elasticType] = {};
  obj[elasticType].query = filter.value;
  obj[elasticType].type = "bool_prefix";
  obj[elasticType].fields = ["addresses.address", "addresses.municipalityText", "owners.name"];

  query.bool.must.push(obj);
};

const addMatchSingleQuery = function (query, filter) {
  if (!filter.value) {
    return;
  }

  let obj = {};
  const elasticType = "match";

  obj[elasticType] = {};
  obj[elasticType][filter.elastic] = filter.value;

  query.bool.must.push(obj);
};

const addMatchMultipleQuery = function (query, filter) {
  if (!filter.value || filter.value.length === 0) {
    return;
  }

  let obj = {};
  const elasticType = "terms";

  obj[elasticType] = {};
  obj[elasticType][filter.elastic] = filter.value;

  query.bool.must.push(obj);
};

const addPolygonQuery = function (query, filter) {
  if (!filter.value || filter.value.length === 0) {
    return;
  }

  let obj = {};
  const elasticType = "geo_polygon";

  obj[elasticType] = {};
  obj[elasticType][filter.elastic] = {};
  obj[elasticType][filter.elastic].points = filter.value.flat().map((coord) => {
    return {
      lat: coord[1],
      lon: coord[0],
    };
  });

  query.bool.filter = obj;
};
