/*
 * Gobal filters
 *
 * Note: The order of the filters is important. The order is the order in which the filters are displayed in the UI.
 */
const GLOBAL_FILTERS = [
  "addresses.municipalityText",
  "valuations.usageText",
  "valuations.propertyValuation",
  "valuations.landValuation",
  "totalPlotArea",
  "totalBuildingArea",
  "totalUnitArea",
  "plots.buildings.buildingCategoryCode",
  "polygon",
  "owners.ownershipText",
  "ownerChanges.ownershipChanges.seller.ownershipText",
  "ownerChanges.handoverDate",
  "ownerChanges.transferType",
  "ownerChanges.transactionInfo.totalPurchaseSum",
  "addresses.postalCode",
  "search-term",
];

/*
 * Condo filters
 *
 * Note: The order of the filters is important. The order is the order in which the filters are displayed in the UI.
 */

const CONDO_FILTERS = ["condo.unit.usageText", "condo.unit.totalArea", "condo.unit.commercialArea"];

/*
 * Main property filters
 *
 * Note: The order of the filters is important. The order is the order in which the filters are displayed in the UI.
 */
const MAIN_PROPERTY_FILTERS = [
  "plots.buildings.usageText",
  "plots.waterAreaInclusion",
  "plots.preservedForestArea",
  "plots.ownersGuildName",
  "plots.registeredArea",
  "plots.buildings.totalUsableArea",
  "plots.buildings.units.commercialArea",
  "plots.buildings.constructionYear",
  "plots.buildings.extensionYear",
  "plots.buildings.energyLabel.classification",
  "plots.rightOfUseArea",
  "plots.duneProtectionArea",
  "plots.roadArea"
];

/*
 * Building on foreign plot filters
 *
 * Note: The order of the filters is important. The order is the order in which the filters are displayed in the UI.
 */
const BOFP_FILTERS = [
  "buildingOnForeignPlot.buildings.usageText",
  "buildingOnForeignPlot.isDividedInCondos",
  "buildingOnForeignPlot.totalBuildingArea",
  "buildingOnForeignPlot.buildings.totalUsableArea",
  "buildingOnForeignPlot.buildings.units.commercialArea"
];

/*
 * Transaction filters
 *
 * Note: The order of the filters is important. The order is the order in which the filters are displayed in the UI.
 */
//const TRANSACTION_FILTERS = ["ownerChanges.transferType", "ownerChanges.handoverDate"];

/*
 * Valuation filters
 *
 * Note: The order of the filters is important. The order is the order in which the filters are displayed in the UI.
 */
const VALUATION_FILTERS = ["valuations.valuatedArea", "valuations.taxableAmount", "valuations.landValuation", "valuations.propertyValuation"];

/*
 * Owner filters
 *
 * Note: The order of the filters is important. The order is the order in which the filters are displayed in the UI.
 */
const OWNER_FILTERS = ["owners.name", "owners.cvrNumber", "administrators.name"];

const PROPERTY_FILTERS = [
  {
    id: "Condo",
    filters: CONDO_FILTERS,
  },
  {
    id: "Main Property",
    filters: MAIN_PROPERTY_FILTERS,
  },
];

export const mergeFiltersWithMetadata = (filters, metadata) => {
  const updatedFilters = [...filters].map((f) => {
    const md = metadata.find((m) => m.id === f.id);

    if (md == null || md.metadataLoaded) return f;

    return {
      ...f,
      ...md,
      metadataLoaded: true,
    };
  });

  return updatedFilters;
};

export const filterSupportedFilters = (filters) => {
  const sf = [...PROPERTY_FILTERS.flatMap((pf) => pf.filters), ...VALUATION_FILTERS, ...OWNER_FILTERS];

  return filters.filter((f) => sf.includes(f.id));
};

export const mapGlobalFilters = (filters) => {
  return filters.filter((f) => GLOBAL_FILTERS.includes(f.id)).sort((a, b) => GLOBAL_FILTERS.indexOf(a.id) - GLOBAL_FILTERS.indexOf(b.id));
};

export const mapValuationFilters = (filters) => {
  return filters.filter((f) => VALUATION_FILTERS.includes(f.id)).sort((a, b) => VALUATION_FILTERS.indexOf(a.id) - VALUATION_FILTERS.indexOf(b.id));
};

export const mapOwnerFilters = (filters) => {
  return filters.filter((f) => OWNER_FILTERS.includes(f.id)).sort((a, b) => OWNER_FILTERS.indexOf(a.id) - OWNER_FILTERS.indexOf(b.id));
};


export const mapPropertyFilters = (filters) => {
  return PROPERTY_FILTERS.map((f) => {
    return {
      id: f.id,
      filters: filters.filter((ff) => f.filters.includes(ff.id)).sort((a, b) => f.filters.indexOf(a.id) - f.filters.indexOf(b.id)),
    };
  });
};

export const FreeTextFilter = {
  id: "search-term",
  label: "EXPLORE_SEARCH_NEW_FILTER_PROPERTY_FREETEXT",
  type: "search",
  description: "EXPLORE_SEARCH_NEW_FILTER_PROPERTY_FREETEXT_PLACEHOLDER",
  value: "",
  active: true,
  enabled: true,
  default: true,
  metadataLoaded: true,
};
